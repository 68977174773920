import React from 'react';

export const ExpandIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="System / cheveron-down">
          <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.86189 5.52827C3.12224 5.26792 3.54435 5.26792 3.8047 5.52827L7.99996 9.72353L12.1952 5.52827C12.4556 5.26792 12.8777 5.26792 13.138 5.52827C13.3984 5.78862 13.3984 6.21073 13.138 6.47108L8.47136 11.1377C8.21101 11.3981 7.7889 11.3981 7.52855 11.1377L2.86189 6.47108C2.60154 6.21073 2.60154 5.78862 2.86189 5.52827Z"
            fill="#F1F1F1"
          />
        </g>
      </svg>
    </div>
  );
};
