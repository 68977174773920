import React, { useEffect, useState } from 'react';

import './index.scss';
import { PlayItem } from './PlayItem';
import { communityConfigItem } from '@/containers/History/HistoryLine/interface';
import img from './imgs/img.jpg';
import img1 from './imgs/img-1.jpg';
import img2 from './imgs/img-2.jpg';
import img3 from './imgs/img-3.jpg';

export const communitiesConfig: communityConfigItem[] = [
  {
    title: 'The Home 🏡 of Onchain Gaming',
    category: '0xKepler',
    website: 'https://www.onchaingaming.world/',
    img: img,
  },
  {
    title: 'On-Chain Space Station',
    category: 'Coreloop',
    website:
      'https://thecoreloop.notion.site/On-Chain-Space-Station-9dd9e3579d984cd8bb0bc2e4a5b7f8f2/',
    img: img1,
  },
  {
    title: 'Autonomous World Library',
    category: 'libweb3',
    website:
      'https://libweb3.notion.site/libweb3/Autonomous-World-s-library-update-as-needed-6cb73c2ee54343e689ffb708c42ff5c3',
    img: img2,
  },
  {
    title: 'AW Research',
    category: 'CheDAO',
    website:
      'https://chedao.notion.site/AW-Research-Collection-234dcfb1fc4441f9b0c14725b01fb921',
    img: img3,
  },
];

export function Communities() {
  const [appType, setAppType] = useState<string>(
    window.innerWidth <= 768 ? 'h5' : 'pc'
  );
  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppType(window.innerWidth <= 768 ? 'h5' : 'pc');
    });
  }, []);

  return (
    <main id="communities" className="communities">
      <section>
        <h1 className="title">Communities</h1>
      </section>
      <section className="playbox">
        {communitiesConfig.map((item, index) => (
          <PlayItem key={index} {...item} />
        ))}
      </section>
      {appType !== 'h5' && (
        <div className="backgroundBox">
          <div className="backBox"></div>
          <div className="backBox"></div>
        </div>
      )}
    </main>
  );
}
