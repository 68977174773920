import React from 'react';

export const ArchiveIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.89394 16.5075C4.69503 16.3086 4.58329 16.0388 4.58329 15.7575C4.58329 15.4762 4.69503 15.2065 4.89394 15.0076C5.09284 14.8087 5.36261 14.6969 5.6439 14.6969H15.8712C16.3314 14.6969 16.7045 14.3238 16.7045 13.8636C16.7045 13.8445 16.7039 13.8256 16.7026 13.8068C16.7039 13.788 16.7045 13.7691 16.7045 13.75V4.16663C16.7045 2.78591 15.5852 1.66663 14.2045 1.66663H5.6439C4.92058 1.66663 4.22689 1.95396 3.71543 2.46543C3.20396 2.97689 2.91663 3.67058 2.91663 4.3939V15.7575C2.91663 16.4809 3.20396 17.1745 3.71543 17.686C4.22689 18.1975 4.92058 18.4848 5.6439 18.4848H15.8712C16.3314 18.4848 16.7045 18.1117 16.7045 17.6515C16.7045 17.1912 16.3314 16.8181 15.8712 16.8181H5.6439C5.36261 16.8181 5.09284 16.7064 4.89394 16.5075Z"
          fill="#F1F1F1"
        />
      </svg>
    </div>
  );
};
