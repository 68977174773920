import {HistoryItemConfig} from "./src/containers/History/HistoryLine/interface";


export const config: HistoryItemConfig[] = [
    {
        type: 'philosophy',
        keyword: 'Lattice',
        time: '2023/7/16',
        title: 'Season One: Digital Physics',
        content: 'Today, we’re announcing the launch of The New World, a series on Mirror that we’ll be using to probe into onchain life. For the next few weeks, during Season One, we’ll be exploring Digital Physics in all of its forms.',
        detailKey: 'b2db1c17d43d46e298519b1f36358191',
    },
    {
        type: 'philosophy',
        keyword: 'taetaehoho',
        time: '2023/5/5',
        title: 'Autonomous Worlds: The Case for Fully On-chain Games',
        content: 'With each new chapter of change, we have seen new teams succeed that embraced new modalities and technologies; not incumbents but rather fresh thinkers — game designers who were able to invent net-new experiences by leveraging new affordances provided by each computing medium.',
    },
    {
        type: 'philosophy',
        keyword: 'Bohdan Melnychuk',
        time: '2023/3/21',
        title: 'On-chain Gaming: Principles for Building Viable Systems',
        content: 'All your on-chain game mechanics must carry a non-trivial mystery that cannot be solved once and for all. The best source for such a mystery is the players themselves.',
    },
    {
        type: 'philosophy',
        keyword: 'The Citadel Game',
        time: '2023/3/1',
        title: 'End-Game Vision for The Citadel',
        content: 'We want to see players write their own stories. The world should respond dynamically to player’s choices, deeds, achievements, and failures.',
    },
    {
        type: 'philosophy',
        keyword: 'VanishK',
        time: '2023/2/25',
        title: 'The Ultimate Form of Blockchain Gaming: Exploring \'Fully On-Chain\' through Dark Forest & Loot',
        content: 'With blockchain technology, we can unlock the potential to create the LEGO version of gaming with highly combinable and expandable features.',
    },
    {
        type: 'philosophy',
        keyword: 'James Brodie',
        time: '2023/2/21',
        title: 'Pureplay On-Chain Games',
        content: 'With open-source code and a permissionless structure, players can customise and modify the games they love, becoming player-creators who are not only invested in the success of the game but also passionate advocates of it.',
        detailKey: 'ac5f9c5c08a54199b3092fd3e3a31837',
    },
    {
        type: 'philosophy',
        keyword: 'Bibliothecadao',
        time: '2023/1/23',
        title: 'Master Scroll: A quest with an objective, but without an end',
        content: 'We see a future world of co-creation between developers, players and publishers, with the line between them becoming forever blurred.',
    },
    {
        type: 'philosophy',
        keyword: 'Will Robinson',
        time: '2022/11',
        title: 'Unblocking On-Chain Games (series) ',
        content: 'To play games on chain, we need to rethink their design. The tail must wag the dog. This approach may seem backwards. After all, when a new technology emerges, you should ask: What new things can I do?, and not: How can I contort what is already working into a new technological paradigm?',
    },
    {
        type: 'philosophy',
        keyword: 'Movng Castles',
        time: '2022/10/30',
        title: 'Three Eras of World Generation',
        content: 'We are entering an age where the audience, again, can transform the underlying structures and narrative logics of the Worlds they inhabit. Real-time rendering engines have offered a first glance into these Worlds; on-chain autonomy will slam their gates open.',
    },
    {
        type: 'philosophy',
        keyword: 'ronan.eth',
        time: '2022/8/19',
        title: 'INFINITE GAMES',
        content: 'By allowing this, Infinite Games become protocols themselves and worlds of their own. They also become an invitation to build. Indeed, such games allow extensions to be hyperstructures themselves.',
    },
    {
        type: 'philosophy',
        keyword: '0xParc',
        time: '2022/8/6 ',
        title: 'Autonomous Worlds',
        content: 'Autonomous Worlds have hard diegetic boundaries, formalised introduction rules, and no need for privileged individuals to keep the World alive.',
    },
    {
        type: 'philosophy',
        keyword: 'guiltygyoza @topology',
        time: '2022/7/23',
        title: 'Game 2.0',
        content: 'If mobile brought casualness to gaming, blockchain will bring significance to gaming.',
    },
    {
        type: 'philosophy',
        keyword: 'Moving Castles',
        time: '2021/8',
        title: 'Modular and Portable Multiplayer Miniverses',
        content: 'We now choose our own headless gods.',
    },
    {
        type: 'philosophy',
        keyword: 'gubsheep',
        time: '2021/7',
        title: 'The Strongest Crypto Gaming Thesis Why we\'re building @darkforest_eth',
        content: 'A crypto-native game is a game that maximally embraces the architectural patterns and ethos of blockchain application development.',
    },
    {
        type: 'instance',
        time: '2023/5/25',
        title: 'Loot Survivor was released',
        content: '',
        keyword: ''
    },
    {
        type: 'instance',
        time: '2023/4/1',
        title: 'MUD v2 was released',
        content: '',
        keyword: 'mudv2'
    },
    {
        type: 'instance',
        time: '2023/2/5',
        title: 'Dojo was released',
        content: 'Originating from core contributors of Loot Realms @lordOfAFew, Cartridge founder @tarrenceva, and Briq founder @sylvechv, Dojo is a game engine built on Starknet.',
        keyword: 'dojo-released'
    },
    {
        time: '2023/1/26',
        type: 'instance',
        title: 'Realms: Ethernum Alpha',
        content: 'A Loot-derived MMO on-chain game developed by Bibliotheca DAO on Starknet. Users are the lords holding Loot Realms NFTs.',
        keyword: 'realms'
    },
    {
        time: '2022/12/14',
        type: 'instance',
        title: 'Sky Strife Playtest',
        content: '',
        keyword: 'sky-strife-playtest',
    },
    {
        time: '2022/11/25',
        type: 'instance',
        title: 'Cairo 1.0 was announced',
        content: '',
        keyword: 'Cairo1-0',
    },
    {
        time: '2022/11/22',
        type: 'instance',
        title: 'MUD v1 was released',
        content: 'A fully on-chain game engine developed by Lattice, based on Solidity.',
        keyword: 'mudv1'
    },
    {
        time: '2022/10/19',
        type: 'instance',
        title: 'OPCraft was released',
        content: '',
        keyword: 'opcraft-released'
    },
    {
        time: '2022/5/9',
        type: 'instance',
        title: 'Cartridge was released',
        content: '',
        keyword: 'cartridge-released'
    },
    {
        time: '2022/2/28',
        type: 'instance',
        title: 'Dark Forest v0.6 Ended',
        content: 'In this round, dfdao created a player-made wonder: the seemingly invincible "Death Star." As a result, a space opera-like struggle about war, power, agreements, and surrender erupted between dfdao and another elite player organization, Ordengg, eventually ending through diplomatic means.',
        link: 'https://twitter.com/wiiichang/status/1503751757834379270',
        keyword: ''
    },
    {
        time: '2022/1/4',
        type: 'instance',
        title: 'Lattice',
        content: 'Originating from the 0xParc team, Lattice established MUD and is also a major proponent of the autonomous worlds concept.',
        keyword: ''
    },
    {
        time: '2021/10/26',
        type: 'instance',
        title: '0xParc',
        content: 'A collaboration between the pioneers of the on-chain game Dark Forest team and several other projects, focusing on concepts such as autonomous worlds and ZK technology.',
        keyword: ''
    },
    {
        time: '2021/11/23',
        type: 'instance',
        title: 'Realm: Ethereum → Starknet',
        content: '',
        keyword: ''
    },
    {
        time: '2021/10/24',
        type: 'instance',
        title: 'Briq was released',
        content: 'A NFT design protocol where you build NFTs from fundamental particles called briqs. Briq features a highly modular design and is also an active contributor to Starknet and Dojo.',
        keyword: ''
    },
    {
        time: '2021/9/1',
        type: 'instance',
        title: 'Loot Realms was released',
        content: 'A collection consisting of 8,000 procedurally generated NFTs. Holders can play the Realms: Eternum game and participate in Bibliotheca DAO governance.',
        keyword: ''
    },
    {
        time: '2021/8/31',
        type: 'instance',
        title: 'Dope War was released',
        content: 'A Loot-styled Free Mint project with a total of 8,000 pieces. Another active and important branch within the Loot ecosystem besides Loot Realms.',
        keyword: ''
    },
    {
        time: '2021/8/28',
        type: 'instance',
        title: 'Loot was released',
        content: 'A fully decentralized, community-defined project. Simple, open, and with unlimited narrative potential.',
        keyword: ''
    },
    {
        time: '2020/8/7',
        type: 'instance',
        title: 'Dark Forest was released',
        content: 'The first on-chain game developed by Brian Gu @gubsheep, utilizing zk-SNARK technology, and the most influential on-chain game to date.',
        keyword: ''
    },
]