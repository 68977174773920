import React, { ReactNode, useEffect, useState } from 'react';
import { HistoryLine } from './HistoryLine';
import { PhilosophyIcon } from './HistoryLine/PhilosophyIcon';
import { InstanceIcon } from './HistoryLine/InstanceIcon';

import './index.scss';

export function History() {
  const [appType, setAppType] = useState<string>(
    window.innerWidth <= 768 ? 'h5' : 'pc'
  );
  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppType(window.innerWidth <= 768 ? 'h5' : 'pc');
    });
  }, []);
  return (
    <>
      <main id="connected" className="connected">
        <HistoryLine />
        {appType !== 'h5' && (
          <div className="backgroundBox">
            <div className="backBox">
              <PhilosophyIcon />
            </div>
            <div className="backBox">
              <InstanceIcon />
            </div>
          </div>
        )}
      </main>
    </>
  );
}
