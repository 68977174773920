import React, { useEffect, useMemo, useState } from 'react';
import { Line } from '../../../components/Timeline';
import { HistoryItemConfig } from './interface';
import { config } from '../../../../history.config';

import './style.scss';
import { ArchiveIcon } from './ArchiveIcon';
import {ExpandIcon} from './ExpandIcon';
import { Link } from 'gatsby';

const afterSortConfig = config.sort(
  (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
);

function PhilosophyView(props: HistoryItemConfig) {
  if (props.type === 'instance') {
    return <div className="left"></div>;
  }
  const { keyword, time, title, content, detailKey = '' } = props;
  return (
    <div className="left">
      <button className="awBtn">
        {keyword} · {time}
      </button>
      {detailKey ? (
        <a href={`/detail/${detailKey}`} target={'_blank'} className="title">
          {title}
        </a>
      ) : (
        <div className="title">{title}</div>
      )}
      <div className="content">{content}</div>
    </div>
  );
}

function InstanceView(props: HistoryItemConfig) {
  const { time, title, content, keyword, link = '' } = props;
  if (props.type === 'philosophy') {
    return <div className="right"></div>;
  }
  return (
    <div className="right">
      <button className="awBtn">{time}</button>
      <div className="contentWrapper">
        {link ? (
          <a href={link} target={'_blank'} className="title">
            {title}
          </a>
        ) : (
          <div className="title">{title}</div>
        )}
        {content && <div className="content">{content}</div>}
      </div>
    </div>
  );
}

function CommonView(props: HistoryItemConfig) {
  const { time, title, content, keyword, detailKey = '', link = '' } = props;
  if (props.type === 'philosophy') {
    return (
      <div className="right common">
        <button className="awBtn">
          {keyword} · {time}
        </button>
        {detailKey ? (
          <a href={`/detail/${detailKey}`} target={'_blank'} className="title">
            {title}
          </a>
        ) : (
          <div className="title">{title}</div>
        )}
        <div className="content">{content}</div>
      </div>
    );
  }
  return (
    <div className="right">
      <button className="awBtn">{time}</button>
      <div className="contentWrapper">
        {link ? (
          <a href={link} target={'_blank'} className="title">
            {title}
          </a>
        ) : (
          <div className="title">{title}</div>
        )}
        {content && <div className="content">{content}</div>}
      </div>
    </div>
  );
}

function HistoryRow(props: { data: HistoryItemConfig; appType?: string }) {
  const { data, appType = 'pc' } = props;
  if (appType === 'h5') {
    return (
      <div className="item">
        <Line />
        <CommonView {...data} />
      </div>
    );
  }
  return (
    <div className="item">
      <PhilosophyView {...data} />
      <Line />
      <InstanceView {...data} />
    </div>
  );
}

export const HistoryLine = (props: any) => {
  const [appType, setAppType] = useState<string>(
    window.innerWidth <= 768 ? 'h5' : 'pc'
  );
  const [showAll, setShowAll] = useState(false);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppType(window.innerWidth <= 768 ? 'h5' : 'pc');
    });
  }, []);

  const list = useMemo(() => {
    if (showAll) {
      return afterSortConfig;
    } else {
      return afterSortConfig.slice(0, 4);
    }
  }, [showAll, afterSortConfig]);

  return (
    <div className="historyLine">
      {list.map((item, index) => (
        <HistoryRow appType={appType} key={index} data={item} />
      ))}
      <div className="footer">
        {showAll ? (
          <button className="awBtn archiveBtn awBtnHoverStyle awBtn-1">
            <ArchiveIcon />
            <Link to="/archive">AW's Historical Archive</Link>
          </button>
        ) : (
          <button
            onClick={() => {
              setShowAll(true);
            }}
            className="awBtn awBtnHoverStyle expandMoreBtn"
          >
            Expand More <ExpandIcon />
          </button>
        )}
      </div>
    </div>
  );
};

// export function HistoryLine() {
//   return (
//     <div className="historyLine">
//       {config.map((item) => <HistoryRow {...item} />)}
//     </div>
//   )
// }
