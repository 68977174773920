import React, { useEffect, useState } from 'react';
import { Attention2Icon, AttentionIcon, LogoIcon } from '../../icons';

import './style.scss';
import { CloseIcon } from './CloseIcon';

function WhatsAWDetail({ onClose }: { onClose: () => void }) {
  return (
    <div className="awDetail">
      <div className="main">
        <div>
          The telescopes used to observe Mars can be built by anyone. This makes
          it easier for us to agree on the fact that "yes, there is a big red
          sphere out there, and you didn't make it up". Additionally, the rocks
          and deserts on Mars keep existing if someone stops believing in their
          World. Nobody can "unplug" Mars.
        </div>
        <div>
          Likewise, Autonomous Worlds have "telescopes" that anyone can build
          and use to reach consensus.
        </div>
        <div>
          The entities of Autonomous Worlds remain diegetic as long as at least
          one person participates in the digital consensus. The introduction
          rule remains objective as well as transparent, and observing the state
          of the World is open to anyone with the right telescope. Nobody can
          unplug Autonomous Worlds.
        </div>
        <div>
          Autonomous Worlds have hard diegetic boundaries, formalised
          introduction rules, and no need for privileged individuals to keep the
          World alive.
        </div>
        <div className="buttonGroup">
          <button
            className="awBtn awBtnHoverStyle"
            onClick={() => {
              window.open('https://0xparc.org/blog/autonomous-worlds');
            }}
          >
            <AttentionIcon />
            Genesis
          </button>
        </div>
      </div>
      <div className="close">
        <CloseIcon className="closeBtn" onClick={onClose} />
      </div>
    </div>
  );
}

export function WhatsAw() {
  const [show, setShow] = useState(false);

  const [appType, setAppType] = useState<string>(
    window.innerWidth <= 768 ? 'h5' : 'pc'
  );
  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppType(window.innerWidth <= 768 ? 'h5' : 'pc');
    });
  }, []);

  return (
    <div className="mainBox">
      <div className="headerMainBox">
        <LogoIcon className="logo" />
        <div className="titleTag">Autonomous Worlds Academy</div>
        <div className="title">STORYINE OF INTEROBJECTIVE REALITIES</div>
      </div>
      <div className="whatsAW">
        <div className="lookAt">
          {show ? (
            <WhatsAWDetail onClose={() => setShow(false)} />
          ) : (
            <button className="awBtn lookAtBtn" onClick={() => setShow(true)}>
              <Attention2Icon />
              What's AW
            </button>
          )}
        </div>
      </div>

      {appType !== 'h5' && (
        <div className="backgroundBox">
          <div className="backBox"></div>
          <div className="backBox"></div>
        </div>
      )}
    </div>
  );
}
