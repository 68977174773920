import React from 'react';
import game1Img from '../../../../static/gam1@2x.png';
import { ShareIcon } from '../../OngoingPlaytest/PlayItem/svgs/ShareIcon';
import { CenterLineIcon } from '../../OngoingPlaytest/PlayItem/svgs/CenterLineIcon';
import { communityConfigItem } from '@/containers/History/HistoryLine/interface';

export function PlayItem(props: communityConfigItem) {
  const { img, category, title, website } = props;
  return (
    <div
      className="playitem"
      onClick={e => {
        if (website) {
          window.open(website, '_blank');
        }
      }}
    >
      <div className="imgbox">
        <img src={img || game1Img} alt="" className="gameImg" />
      </div>
      <div className="content">
        <div className="h1">{title}</div>
      </div>
      <div className="bottomContent">
        <div className="category">{category}</div>
        {(website) && <CenterLineIcon />}
        <div className="sharebox">
          <div className=""></div>
          {website && (
            <a
              href={website}
              target={'_blank'}
              onClick={e => e.stopPropagation()}
            >
              <ShareIcon />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
