import React from 'react';

export const CloseIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_82_6387)">
          <rect
            width="32"
            height="32"
            rx="16"
            fill="#10100E"
            fill-opacity="0.7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5286 11.5286C11.7889 11.2682 12.211 11.2682 12.4714 11.5286L16 15.0572L19.5286 11.5286C19.7889 11.2682 20.2111 11.2682 20.4714 11.5286C20.7317 11.7889 20.7317 12.211 20.4714 12.4714L16.9428 16L20.4714 19.5286C20.7317 19.7889 20.7317 20.211 20.4714 20.4714C20.2111 20.7317 19.7889 20.7317 19.5286 20.4714L16 16.9428L12.4714 20.4714C12.211 20.7317 11.7889 20.7317 11.5286 20.4714C11.2682 20.211 11.2682 19.7889 11.5286 19.5286L15.0572 16L11.5286 12.4714C11.2682 12.211 11.2682 11.7889 11.5286 11.5286Z"
            fill="#F1F1F1"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_82_6387"
            x="-13"
            y="-13"
            width="58"
            height="58"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_82_6387"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_82_6387"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
