import React from 'react';
import Layout from '../components/Layout';
import { History } from '../containers/History';
import { OngoingPlaytest, playsConfig } from '../containers/OngoingPlaytest';

import WindowProvider from '../components/WinowProvider';
import SEO from '../components/Seo';
import { WhatsAw } from '../containers/WhatsAw';

import './index.scss';
import { Communities } from '../containers/Communities';

const Web3MQ = () => {
  return (
    <WindowProvider>
      <SEO>
        <Layout>
          <WhatsAw />
          <History />
          <OngoingPlaytest isFull={false}/>
          <Communities />
        </Layout>
      </SEO>
    </WindowProvider>
  );
};

export default Web3MQ;
