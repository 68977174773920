import PropTypes from "prop-types";
import React from "react";
import vector from './vector.svg';
import "./style.css";

interface Props {
  className?: string;
  ellipseClassName?: string;
  vector?: string;
}
export const Line = ({ className, ellipseClassName }: Props) => {
  return (
    <div className={`line ${className}`}>
      <div className={`ellipse ${ellipseClassName}`} />
      <div className="vector"  />
    </div>
  );
};

Line.propTypes = {
  vector: PropTypes.string,
};